<!--
 * @Author: cyy
 * @version: 1.4.17
 * @Date: 2022-07-28 10:10:54
 * @LastEditTime: 2022-08-03 16:35:18
 * @LastEditors: cyy
 * @Description: 超级会员
 * @FilePath: \teacherdingding.com\src\views\Survey\manageSuperMember\superMumber.vue
-->
<template>
  <div class="superMumber">
    <div
      v-if="$route.path === '/manageSuperMember'"
      style="padding: 20px; background-color: #fff;"
    >
      <div class="search">
        <el-button
          type="primary"
          size="medium"
          @click="
            $router.push({
              path: '/manageSuperMember/newSupermember',
              query: { id: 'new' },
            })
          "
        >
          创建超级会员
        </el-button>
        <div class="flex-center">
          <el-form
            ref="ruleIngredientsForm"
            label-width="60px"
            class="flex-center"
            :model="search"
            size="small"
            :rules="rules"
          >
            <el-form-item label="有效期" prop="end_validity">
              <div class="search-item8">
                <div class="vm select">
                  <div class="flex-center" style="width:100%">
                    <el-input
                      size="medium"
                      :value="search.start_validity"
                      @input="
                        search.start_validity = toNumber(arguments[0], true)
                      "
                      clearable
                    >
                      <template slot="append">天</template>
                    </el-input>
                    <span class="middle-space">至</span>
                    <el-input
                      size="medium"
                      :value="search.end_validity"
                      @input="
                        search.end_validity = toNumber(arguments[0], true)
                      "
                      clearable
                    >
                      <template slot="append">天</template>
                    </el-input>
                  </div>
                </div>
              </div>
            </el-form-item>

            <el-form-item label="状态" prop="status">
              <div class="search-item8">
                <el-select v-model="search.status" size="medium">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </div>
            </el-form-item>
          </el-form>
          <div class="search-item8">
            <el-input
              clearable
              v-model="search.search"
              @keyup.enter.native="filterStart"
              style="width:218px"
              class="ml20"
              size="medium"
              placeholder="输入名称搜索"
            ></el-input>
          </div>
          <div class="search-item8">
            <el-button
              type="primary"
              size="medium"
              class="ml20"
              @click="filterStart"
            >
              搜索
            </el-button>
          </div>
        </div>
      </div>

      <pagination2 :option="post" url="/member/memberList" ref="childDialogBox">
        <template v-slot:default="{ tableData }">
          <el-table
            :header-cell-style="{
              background: 'rgba(245,245,245,1)',
              color: '#333333',
            }"
            :data="tableData"
          >
            <el-table-column label="会员名称" prop="name"></el-table-column>
            <el-table-column label="权益" prop="equity"></el-table-column>
            <el-table-column label="有效期">
              <template slot-scope="{ row }">
                <span v-if="row.validity == 0">长期有效</span>
                <span v-else>{{ row.validity }}天</span>
              </template>
            </el-table-column>
            <el-table-column
              label="有效会员人数"
              prop="user_num"
            ></el-table-column>
            <el-table-column label="状态">
              <template slot-scope="{ row }">
                <span style="color:#3D80EF" v-if="row.status == 1">使用中</span>
                <span v-else class="redcolor">停用</span>
              </template>
            </el-table-column>
            <el-table-column label="创建时间">
              <template slot-scope="{ row }">
                {{ row.c_time | formatTimeStamp }}
              </template>
            </el-table-column>
            <el-table-column label="操作" width="210px" align="right">
              <template slot-scope="{ row }">
                <!-- 详情  |   编辑  |  推广  |  停用 -->
                <div class="btns">
                  <el-button
                    type="text"
                    @click="
                      $router.push({
                        path: '/manageSuperMember/surperDetail',
                        query: { id: row.m_id },
                      })
                    "
                  >
                    详情
                  </el-button>
                  <el-divider direction="vertical"></el-divider>
                  <el-button
                    type="text"
                    @click="
                      $router.push({
                        path: '/manageSuperMember/newSupermember',
                        query: { id: row.m_id },
                      })
                    "
                  >
                    编辑
                  </el-button>
                  <el-divider
                    direction="vertical"
                    v-if="row.status == 1"
                  ></el-divider>
                  <el-button
                    type="text"
                    @click="tuiguang(row)"
                    v-if="row.status == 1"
                  >
                    推广
                  </el-button>
                  <el-divider direction="vertical"></el-divider>
                  <el-button
                    type="text"
                    @click="setStatus(row, 1)"
                    v-if="row.status == 1"
                  >
                    停用
                  </el-button>
                  <el-button type="text" @click="setStatus(row, 2)" v-else>
                    启用
                  </el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </pagination2>

      <!-- 推广 -->
      <extension
        v-if="status"
        :selectrow="selectrow"
        :dialogstatus.sync="status"
      ></extension>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import bg from '@/mixin/background'
import extension from './components/extension'
export default {
  name: 'superMumber',

  mixins: [bg],

  components: {
    extension,
  },

  data() {
    var validatePass2 = (rule, value, callback) => {
      if (
        typeof this.search.end_validity === 'number' &&
        this.search.end_validity < this.search.start_validity
      ) {
        this.tosearch = false
        callback(new Error('最大天数不能小于最小天数'))
      } else {
        this.tosearch = true
        callback()
      }
    }
    return {
      options: [
        { label: '全部', value: 0 },
        { label: '使用中', value: 1 },
        { label: '停用', value: 2 },
      ],
      rules: {
        end_validity: [{ validator: validatePass2, trigger: 'blur' }],
      },

      post: {},

      selectrow: {},

      status: false,

      search: {
        search: '',
        start_validity: '',
        end_validity: '',
        status: 0,
      },
    }
  },

  methods: {
    // 停用/启用
    async setStatus(row, val) {
      if (val == 1) {
        this.$confirm('是否确定停用该会员？', '停用', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        })
          .then(async () => {
            await this.setfn(row, val)
          })
          .catch(() => {})
      } else {
        await this.setfn(row, val)
      }
    },

    async setfn(row, val) {
      await this.$http({
        url: '/member/setStatus',
        data: {
          m_id: row.m_id,
          status: val,
        },
        successMsg: true,
      })
      this.reset()
    },

    // 推广
    tuiguang(row) {
      this.selectrow = row
      this.$nextTick(() => {
        this.status = true
      })
    },

    reset() {
      this.$refs.childDialogBox.reset()
    },

    // 筛选
    filterStart() {
      if (this.$refs['ruleIngredientsForm']) {
        this.$refs['ruleIngredientsForm'].validate(valid => {
          if (valid) {
            this.post = _.assign({}, this.search)
            this.reset()
          } else {
            return false
          }
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.superMumber {
  margin: 20px;
  .search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    ::v-deep .el-input-group--append .el-input__inner {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
    .middle-space {
      font-size: 14px;
      line-height: 19px;
      margin: 0 10px;
      color: rgba(51, 51, 51, 1);
    }
    .search-text {
      min-width: 56px;
      font-size: 14px;
      line-height: 19px;
      margin-right: 10px;
      color: rgba(51, 51, 51, 1);
    }

    ::v-deep .el-form-item--small.el-form-item {
      margin-bottom: 0;
    }
    .search-item {
      .middle-space {
        font-size: 14px;
        line-height: 19px;
        margin: 0 10px;
        color: rgba(51, 51, 51, 1);
      }
    }
    ::v-deep .search-item5,
    ::v-deep .search-item7,
    ::v-deep .search-item8 {
      .el-input {
        width: 130px;
        position: relative;
        input[type='number'] {
          padding-right: 50px;
        }
        .el-input-group__append {
          position: absolute;
          right: 0;
          background: none;
          top: 0;
          height: 100%;
          border: 0;
          line-height: 36px;
          width: auto;
          padding: 0;
          padding-right: 10px;
        }
      }
    }
  }
  .btns {
    text-align: right;
  }

  ::v-deep .el-divider {
    background-color: #1b9d97 !important;
  }
}
</style>
